<template>
    <b-modal v-model="openedModal" centered :title="`${(type === 'add' ? 'Dodaj' : 'Uredi')} podkategorijo`" hide-footer>
        <validation-observer ref="validate">
            <b-form>
                <validation-provider #default="{ errors }" name="naslov kategorije" rules="required|max:30">
                    <b-form-group label="Naslov">
                        <b-form-input v-model="category.title" placeholder="NASLOV"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>
                <b-form-group class="text-right">
                    <b-button variant="secondary" @click="validateForm">{{ (type === 'add' ? 'DODAJ' : 'UREDI') }}</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, max } from '@validations'
    import {BButton, BForm, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BModal,
            BForm,
            BFormGroup,
            BFormInput,
            BButton
        },
        props: {
            type: {
                type: String,
                reqired: true
            },
            category: {
                type: Object,
                reqired: true
            },
            parentId: {
                type: String,
                reqired: false
            }
        },
        data() {
            return {
                openedModal: false,
                required, 
                max
            }
        },
        methods: {
            openModal() {
                this.openedModal = true
            },
            validateForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        if (this.type === 'add') this.addCategory()
                        else if (this.type === 'edit') this.editCategory()
                    }
                })
            },
            addCategory() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/c2c/category/${this.parentId}`, this.category)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Podkategorija je dodana')
                    thisIns.category.title = ''
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            editCategory() {
                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/c2c/category/${this.category.id}`, this.category)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Podkategorija je urejena')
                    thisIns.category.title = ''
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }
</script>

<style scoped>

</style>