<template>
    <b-overlay :show="!dataLoaded">
        <b-card>
            <b-button variant="primary" @click="addCategory" v-if="$hasPermissions($permissions.EditClassifiedAd)">Dodaj kategorijo</b-button>

            <b-col v-for="category of categories" :key="category.id">
                <b-card v-if="!category.parent_category_id" no-body border-variant="light" style="border-width: 3px !important;" class="mt-1">
                    <b-card-header style="background-color: #f6f6f6" header-tag="header" class="p-1" role="tab">
                        <b-row align="left" class="w-100">
                            <b-col cols="12" md="9" class="w-100">
                                <b-button block v-b-toggle :href="'#category-' + category.id" @click.prevent variant="grey">
                                    <span style="font-size: 16px; font-weight: bold;" class="d-flex text-left">{{category.title}}</span>
                                </b-button>
                            </b-col>
                            <b-col cols="12" md="3" align="right" class="d-flex justify-content-center justify-content-lg-end align-items-center" v-if="$hasPermissions($permissions.EditClassifiedAd)">
                                <b-button class="mr-1" variant="warning" @click="editCategory(category)"><fa icon="edit"/></b-button>
                                <b-button @click="deleteCategory(category.id)" variant="danger"><fa icon="trash"/></b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="'category-' + category.id" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <template  v-if="category.children && category.children.length">

                                <b-list-group flush>
                                    <b-list-group-item v-for="(childCategory, index) of category.children" :key="index">
                                        <b-row>
                                            <b-col cols="12" md="8" align="left" class="ml-2">
                                                <p>{{childCategory.title}}</p>
                                            </b-col>
                                            <b-col cols="12" md="3" align="right" class="d-flex justify-content-center justify-content-lg-end align-items-center" v-if="$hasPermissions($permissions.EditClassifiedAd)">
                                                <b-button size="sm" class="mr-1" variant="warning" @click="editChildrenCategory(childCategory)"><fa icon="edit"/></b-button>
                                                <b-button size="sm" @click="deleteCategory(childCategory.id)" variant="danger"><fa icon="trash"/></b-button>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                            </template>
                            <template  v-else>

                                <b-alert class="p-1" show variant="warning">Ta kategorija še ne vsebuje pod kategorij</b-alert>
                            </template>
                        </b-card-body>
                        <b-card-body>
                            <b-button variant="primary" @click="addChildrenCategory(category.id)" v-if="$hasPermissions($permissions.EditClassifiedAd)">Dodaj podkategorijo</b-button>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-card>
        <CategoryModal ref="categoryModal" :type="type" :category="category" v-on:success="getCategories"/>
        <ChildrenCategoryModal ref="childrenCategoryModal" :type="type" :category="category" :parent-id="parentId" v-on:success="getCategories"/>
    </b-overlay>
</template>
<script>
    import Vue from 'vue'
    import CategoryModal from '@/views/Admin/Category/CategoryModal'
    import ChildrenCategoryModal from '@/views/Admin/Category/ChildrenCategoryModal'
    import {BButton, BCard, BCol, BOverlay, BRow, BCardBody, BListGroup, BListGroupItem, BCardHeader, BAlert, BCollapse, VBToggle} from 'bootstrap-vue'
    export default {
        components: {
            CategoryModal,
            ChildrenCategoryModal,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            BCardBody,
            BListGroup,
            BListGroupItem,
            BCardHeader,
            BAlert,
            BCollapse
        },
        data() {
            return {
                dataLoaded: false,
                categories: [],
                addCategoryModal: false,
                editCategoryModal: false,
                addchildrenCategoryModal: false,
                editchildrenCategoryModal: false,
                categoryName: '',
                childrenCategoryName: '',
                category: {title: '', description: '', parent_category_id: ''},
                buttonTitle: 'Dodaj',
                edit: false,
                type: '',
                parentId: ''
            }
        },
        methods: {
            getCategories() {
                const thisIns = this
                thisIns.dataLoaded = false
                const loadPromise = this.$http.get('/api/management/v1/c2c/category/')
                loadPromise.then(function(response) {
                    thisIns.categories = response.data
                    thisIns.dataLoaded = true
                }).catch(function(error) {
                    thisIns.dataLoaded = true
                    thisIns.$printError(error.response.data)
                })
            },
            addCategory() {
                this.type = 'add'
                this.category = {title: '', description: '', parent_category_id: ''}
                this.$refs.categoryModal.openModal()
            },
            editCategory(category) {
                this.type = 'edit'
                this.category = category
                this.$refs.categoryModal.openModal()
            },
            addChildrenCategory(id) {
                this.type = 'add'
                this.category = {title: '', description: '', parent_category_id: ''}
                this.parentId = id
                this.$refs.childrenCategoryModal.openModal()
            },
            editChildrenCategory(category) {
                this.type = 'edit'
                this.category = category
                this.$refs.childrenCategoryModal.openModal()
            },
            deleteCategory(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati kategorijo.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        const loadPromise = this.$http.delete(`/api/management/v1/c2c/category/${id}`)
                        loadPromise.then(function() {
                            thisIns.$printSuccess('Kategorija je izbrisana')
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.getCategories()
                        })
                    }
                })
            }
        },
        created() {
            Vue.directive('b-toggle', VBToggle)
            this.getCategories()
        }
    }
</script>
